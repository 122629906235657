import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'

//images
import AboutImage from '../images/Header.jpg'
import BirdImage from '../images/birds.png'

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-image: url(${BirdImage});
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 50%;

  
`

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 80%;
  height: 100%;

  //breakpoint for mobile
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
  flex: 1;
  height: 100%;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background-color: #870000;
  overflow: hidden;
  position: relative;

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 350px;
    height: 350px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  scale: 1.2;
  object-fit: cover;
  transition: all 350ms;
  &:hover {
    scale: 1.1;
  }
`

const Info = styled.div`
  position: absolute;
  top: 25%;
  left: 15%;
  transform: translate(-50%, -50%);
  background-color: #fe4880;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  z-index: 10;
  font-family: "Schoolbell", cursive;
  font-weight: 400;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;

  //breakpoint for mobile
  @media (max-width: 480px) {
    width: 100px;
    height: 100px;
    top: 20%;
    left: 15%;
  }
`

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  border: 3px dotted white;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 80%;
  height: 80%;
  color: white;
  border-radius: 50%;
  span{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 10px;
    span{
      font-size: 20px;
      display: flex;
    }
  }
  `


const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  flex: 1;
  height: 100%;
` 

const Heading = styled.h1`
  color: #fe4880;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 2px;
  margin: 0px;
`

const SecondaryHeading = styled.h3`
  font-size: ${props => props.fontsize ? props.fontsize : "32px"};
  font-weight: 400;
  color: #555;
  margin: 0px;
`


const Description = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  color: #777;
  line-height: 1.6;
`

const BasicHeading = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin: 0px;
`

const ExtraContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;

  //breakpoint for mobile
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 15px;
  flex: 1;
  height: 100%;
` 

const Button = styled.button`
  padding: 10px 10px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 5px;
  background-color: #fe4880;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 350ms;
  &:hover {
    background-color: #ff6665;
  }
`



const HomeAbout = () => {

  const navigate = useNavigate()

  const handleButtonClick = () => {
    navigate('/about-us')
  }

  return (
    <Container>
      <Main>
        <Left>
          <Info>
            <InfoContent>
              Trusted By
              <span>
                500+
                <br/>
                Parents
              </span>
            </InfoContent>
          </Info>
          <ImageContainer>
            <Image src={AboutImage} alt="About Us" />
          </ImageContainer>
        </Left>

        <Right>
          <Heading>
            Welcome to NBBS
          </Heading>
          <SecondaryHeading>
            Where Children Grow & Learn
          </SecondaryHeading>
          <SecondaryHeading fontsize={"20px"}>
            School Anthem
          </SecondaryHeading>
          <Description>
            New Blooming Buds Hear our call,
            <br />
            Yes, we promise to stand strong and tall,
            <br />

            New Blooming Buds we sing a aloud,
            <br />

            In thought and action always do you proud,
            <br />

            Let's move the world that is new,
            <br />

            Let's move the time that is few,
            <br />

            Facing every challenge we achieve and lead,
            <br />

            To reach out with compassion that's our creed,
            <br />

            New blooming Buds we thank you too,
            <br />

            For giving me courage and making my base too.          
          </Description>

          <ExtraContainer>
            <Box>
              <BasicHeading>
                Our Vision
              </BasicHeading>

              <Description>
                  Our vision is to create a nurturing and stimulating environment where every child can blossom and thrive. We envision a school that embraces the unique qualities of each child, fostering their holistic development and laying a strong foundation for their future
              </Description>
            </Box>

            <Box>
              <BasicHeading>
                Our Mission
              </BasicHeading>
              <Description>
                Our mission is to nurture and empower every child at New Blooming Buds School, providing a joyful and inclusive learning environment where they can thrive. We strive to foster their curiosity, celebrate their individuality, and lay a solid foundation for their future endeavors.
              </Description>
            </Box>
          </ExtraContainer>


          <Button onClick={handleButtonClick}>
                Learn More About Us
          </Button>
        </Right>
      </Main>
    </Container>
  )
}

export default HomeAbout