import styled, {keyframes} from 'styled-components';
import { useNavigate } from 'react-router-dom';

//Images
import HEADER from '../images/Header.jpeg';
import OPEN from '../images/Open.png';

const pop = keyframes`
      0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100vw - 50px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  height: 90vh;
  background-image: url(${HEADER});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  z-index: 1;

  //breakpoint for mobile
  @media (max-width: 480px) {
    height: 60vh;
    width: calc(100vw - 10px);
    margin: 5px 0px;
  }
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  position: relative;
`;

const Open = styled.div`
  height: 150px;
  width: 150px;
  position: absolute;
  bottom: 20px;
  right: 10px;
  animation: ${pop} 1s infinite;
  //breakpoint for mobile
  @media (max-width: 480px) {
    height: 60px;
    width: 60px;
    bottom: 10px;
    right: 10px;
  }
`

const OpenImage = styled.img`
  height: 100%;
  width: 100%;
`

const Info = styled.div`
  display: flex;
  margin: 40px;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  gap: 20px;
`;

const Title = styled.h1`
  font-size: 64px;
  color: #fecb0f;
  margin: 0;
  padding: 0;
  @media (max-width: 480px) {
    font-size: 30px;
    text-align: center;
  }
`;

const Desc = styled.p`
  font-size: 34px;
  color: #fff;
  padding: 10px;
  margin: 0;
  text-align: center;
  border: 2px dashed #fecb0f;
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const SubHeading = styled.h2`
  font-size: 40px;
  color: #f6ff00;
  margin: 0;
  padding: 0;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;

  //breakpoint of mobile
  @media (max-width: 480px) {
    display: none;
  }
`;

const Button = styled.button`
  padding: 20px 30px;
  font-size: 30px;
  font-weight: 800;
  color: #000000;
  background-color: #fecb0f;
  border: 5px solid white;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #ff6665;
  }
`;

function Banner({handleScroll}) {
  const navigate = useNavigate();


  const handleClick = (link) => {
    navigate(`/${link}`);
  };

  return (
    <Container>
      <Main>
        <Info>
          <Title>New Blooming Buds School</Title>
          <Desc>
            New Blooming Buds School is a school for Play to V.
          </Desc>
          <SubHeading>Prepare Your Child For Success In Life.</SubHeading>

          <ButtonContainer>
            <Button onClick={() => handleClick('enroll')}>Enroll Now</Button>
            <Button onClick={() => handleClick('Contact')}>Contact Us</Button>
          </ButtonContainer>
        </Info>

        <Open>
          <OpenImage src={OPEN} alt="Open" />
        </Open>

      </Main>
    </Container>
  );
}

export default Banner;
